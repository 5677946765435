function toggleNav () {
  const navList = document.querySelector('.nav-list');
  const overlay = document.querySelector('.mobile-overlay');
  navList.classList.contains('open') ? navList.classList.remove('open') : navList.classList.add('open');
  overlay.classList.contains('open') ? overlay.classList.remove('open') : overlay.classList.add('open');
  navList.classList.contains('open') ?
  mobileNav.children[0].classList.add('fa-solid') : mobileNav.children[0].classList.remove('fa-solid');
}

const close = document.querySelector('.close');
const mobileNav = document.querySelector('.burger');
mobileNav.addEventListener('click', () => toggleNav(), false);
close.addEventListener('click', () => toggleNav(), false);


function hideContact () {
  const contactBar = document.querySelector('.get-in-touch');
  window.location.href.includes('contact') ? contactBar.style.display = 'none' : contactBar.style.display = 'flex';
}

window.addEventListener('locationchange', hideContact(), false);


async function postData(url, data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  return response.json()
}

const formElm = document.querySelector('form');
if (formElm) {
  formElm.addEventListener('submit', (e) => {
    e.preventDefault();

    const formData = new FormData(formElm);
    if (e.target['time']?.value) formData.append('time', e.target['time'].value);
    if (e.target['message']?.value) formData.append('message', e.target['message'].value);
    let data = {};
    formData.forEach((v, k) => data[k] = v);

    postData('/form/', data)
      .then((data) => {
        // console.log(data);
        const phone = document.querySelector('.valid-mobile');
        const email = document.querySelector('.valid-email');
        phone.style.display = 'none';
        phone.textContent = '';
        email.style.display = 'none';
        email.textContent = '';

        if (data?.validationError) {
          if (!data.phone) {
            phone.style.display = 'block';
            phone.textContent = 'Not a phone number';
          }
          if (!data.email) {
            email.style.display = 'block';
            email.textContent = 'Not a valid email';
          }
        } else if (data.code && data.code !== 200) {
          throw data;
        } else {
          const formResponse = document.querySelector('.form-response');
          const form = document.querySelector('form');
          const heading = document.querySelector('.form-response h2');
          const p = document.querySelector('.form-response p');
          heading.textContent = data.messageHeading;
          p.textContent = data.messageP;
          form.classList.add('hide');
          formResponse.classList.add('show');
        }
      }).catch((error) => {
        console.log(error);
        const formResponse = document.querySelector('.form-response');
        const form = document.querySelector('form');
        const heading = document.querySelector('.form-response h2');
        const p = document.querySelector('.form-response p');
        const a = document.querySelector('.form-response a');
        const icon = document.querySelector('.fa-face-sunglasses');
        heading.textContent = error.messageHeading;
        p.textContent = error.messageP;
        form.classList.add('hide');
        formResponse.classList.add('show');
        icon.classList.add('fa-face-diagonal-mouth');
        a.textContent = error.emailAddress;
        a.setAttribute('href', `mailto:${error.emailAddress}`);
      });
  });
}
